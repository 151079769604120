/* width */
.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: #2b2b2b;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #d1ef70;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
} */
