.input-switch {
  @apply relative inline-block;
  @apply w-8 h-5;
}

.input-switch input[type="checkbox"] {
  @apply absolute hidden -z-50 opacity-0 h-0 w-0;
}

.input-switch .slider {
  @apply absolute h-2.5 cursor-pointer rounded-full;
  @apply top-[5px] left-0 bottom-0 right-0;
  @apply bg-black transition-all;
}

.input-switch .slider:before {
  @apply absolute w-5 h-5;
  @apply left-0 -bottom-[5px];
  @apply bg-white transition-all rounded-full;
  content: "";
}

/* .input-switch input:checked + .slider {
  @apply bg-white;
} */

.input-switch input:focus + .slider {
  @apply shadow-white;
}

.input-switch input:checked + .slider:before {
  @apply translate-x-4 bg-white;
}
