.plan-track {
  @apply relative bg-white h-3 rounded-full;
  z-index: 20;
}

.plan-track.plan-track-1 {
  @apply bg-bg;
  z-index: 9;
}

.plan-thumb {
  @apply cursor-grab flex-center  h-10 w-10 bg-green rounded-full border-[5px] border-black;
  z-index: 21 !important;
}

.plan-mark {
  @apply h-[2px] w-[2px] rounded-full bg-grey z-10;
  margin: 0px calc(17.5px);
}
