.table {
  @apply w-full;
  @apply flex flex-col gap-2.5;
}

.table .thead {
  @apply rounded-md bg-bg-2 p-2.5;
  z-index: 1;
}

.table .thead .tr {
  @apply flex items-center gap-2.5;
}

.table .thead .tr .th {
  flex: 1;
  @apply flex text-bodySm text-grey;
}

/* .table .thead .tr .th:nth-child(2) {
  @apply pr-[7px];
}

.table .thead .tr .th:nth-child(4) {
  @apply pr-[7px];
}

.table .thead .tr .th:nth-child(5) {
  @apply pr-[14px];
} */

.table .thead .tr .th:first-child .cell {
  @apply -mx-4;
}

.table .tbody {
  @apply flex flex-col gap-2;
}

.table .tbody .tr {
  @apply p-2.5 gap-2.5;
  @apply flex flex-row items-center border-b border-bg rounded-lg;
}

.table .tbody .tr-group:hover .tr {
}

.table .tbody .tr-group:has(.after) .tr:not(.after) {
  @apply border-b-0 rounded-b-none pb-0;
}

.table .tbody .tr.active {
  @apply bg-bg-2;
}

.table .tbody .tr .td {
  flex: 1;
  @apply flex text-white;
}

.table .tbody .tr .td .cell {
  @apply p-3.5 overflow-hidden;
  @apply whitespace-nowrap overflow-ellipsis;
}

.table .tbody .tr.after {
  min-height: 0.5rem;
  max-height: 0.5rem;
  transition: max-height 0.4s ease-in-out;
  @apply rounded-t-none p-0 overflow-hidden;
  @apply border-t-0 rounded-b-lg;
  @apply items-start;
}

.table .tbody .tr.after .after-container {
  @apply py-2 px-2 w-full;
}

.table .tbody .tr-group:hover .tr.after {
  max-height: 10rem;
}
