.modalbase {
  @apply fixed flex w-screen h-dvh items-center justify-center z-[8001] top-0 left-0 overflow-hidden bg-black bg-opacity-50 p-8;
}
.modal {
  @apply flex relative border border-border rounded-[20px];
  max-height: calc(100dvh - 40px);
  /* overflow-y: auto; */
}

.modal-content {
  @apply flex-1;
}
